import React from 'react'
import { Top, Footer, Meta } from '@src/share-component'
import Container from '@material-ui/core/Container';
import { useIntl } from "gatsby-plugin-react-intl"
import Loading from '@src/share-component/loading/loading';
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SFZ from '@src/solutions/pedestrian/sfz-120/sfz.jpg'
import CP120 from '@src/solutions/pedestrian/cp-120/cp-120.png'
import Pedestrian from '@src/asset/walk.jpg'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { navigate } from 'gatsby'

import './solutions.css'

const list1 = [1, 2, 3]

const category = 'payment'

const SolutionsPedestrian = () => {

  const intl = useIntl()
  const onClick = (url) => navigate(`${intl.locale}${url}`)

  return <React.Fragment>
    <Meta
     title = {intl.formatMessage({ id : 'solutions-payment-title'})}
     description = {intl.formatMessage({ id : 'solutions-pedestrian-description'})}
     slug = {`/solutions/${category}/`}
     />
    <Loading/>
    <div>
    <Top/>
    <Container classes = {{ root : "solutions-container"}}>
      <Typography variant = "h3" component = "h2" classes = {{ root : 'solutions-title' }}> {intl.formatMessage({ id : 'solutions-pedestrian-title-1'})} </Typography>
      <div class = 'solutions-img-container'><img class = 'solutions-img' src = {Pedestrian} alt = { intl.formatMessage({ id : 'solutions-pedestrian-title'}) }/></div>
      <Typography variant = "body1" component = "p" classes = {{ root : 'solutions-content' }}> {intl.formatMessage({ id : 'solutions-pedestrian-content-1-1'})} </Typography>
      
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-pedestrian-list-1-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      <Typography variant = "body1" component = "p" classes = {{ root : 'solutions-content' }}> {intl.formatMessage({ id : 'solutions-pedestrian-content-1-2'})} </Typography>
      
      <Typography variant = "h3" component = "h2" classes = {{ root : 'solutions-title' }}> {intl.formatMessage({ id : 'solutions-pedestrian-title-2'})} </Typography>
      <div class = 'solutions-img-container'><img class = 'solutions-img' src = {SFZ} alt = {intl.formatMessage({ id : 'solutions-pedestrian-title-2'})}/></div>
      <Typography variant = "body1" component = "p" classes = {{ root : 'solutions-content' }}> {intl.formatMessage({ id : 'solutions-pedestrian-content-2-1'})} </Typography>
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-pedestrian-list-2-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      <Typography variant = "body1" component = "p" classes = {{ root : 'solutions-content' }}> {intl.formatMessage({ id : 'solutions-pedestrian-content-2-2'})} </Typography>
      
      <Button classes = {{ root : 'solutions-button-more' }} variant = "contained" color = 'primary'>
        <Link color = 'initial' classes = {{ root : 'solutions-link' }} href = {`/${intl.locale}/solutions/pedestrian/sfz-120`} underline = 'none'>
        <Typography variant = "body1" component = "h6"> {intl.formatMessage({ id : `solutions-pedestrian-more-1`})} </Typography>

        </Link>
      </Button>
      <Typography variant = "h3" component = "h2" classes = {{ root : 'solutions-title' }}> {intl.formatMessage({ id : 'solutions-pedestrian-title-3'})} </Typography>
      <div class = 'solutions-img-container'><img class = 'solutions-img' src = {CP120} alt = {intl.formatMessage({ id : 'solutions-pedestrian-title-3'})}/></div>
      <Typography variant = "body1" component = "p" classes = {{ root : 'solutions-content' }}> {intl.formatMessage({ id : 'solutions-pedestrian-content-3-1'})} </Typography>
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-pedestrian-list-3-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      <Typography variant = "body1" component = "p" classes = {{ root : 'solutions-content' }}> {intl.formatMessage({ id : 'solutions-pedestrian-content-3-2'})} </Typography>
      
      <Button classes = {{ root : 'solutions-button-more' }} variant = "contained" color = 'primary'>
        <Link color = 'initial' classes = {{ root : 'solutions-link' }} href = {`/${intl.locale}/solutions/pedestrian/cp-120`} underline = 'none'>
        <Typography variant = "body1" component = "h6"> {intl.formatMessage({ id : `solutions-pedestrian-more-2`})} </Typography>

        </Link>
      </Button>
      <Typography variant = "h3" component = "h2" classes = {{ root : 'solutions-title' }}> {intl.formatMessage({ id : 'solutions-pedestrian-title-4'})} </Typography>
      <Typography variant = "body1" component = "p" classes = {{ root : 'solutions-content' }}> {intl.formatMessage({ id : 'solutions-pedestrian-content-4-1'})} </Typography>
      <List>
        {
          list1.map((l) => <ListItem>
            <ListItemIcon classes = {{ root : "solutions-list-item" }}></ListItemIcon>
            <Typography variant = "body1" component = "h6" classes = {{ root : 'solutions-list-item-text' }}> {intl.formatMessage({ id : `solutions-pedestrian-list-4-${l}`})} </Typography>
            {/* <ListItemText component = "h6" classes = {{ root : "solutions-list-item-text" }} primary = "Access control devices, such as access cards and card readers"></ListItemText> */}
          </ListItem>
          )
        }
      </List>
      <Typography variant = "body1" component = "p" classes = {{ root : 'solutions-content' }}> {intl.formatMessage({ id : 'solutions-pedestrian-content-4-2'})} </Typography>
    </Container>

    <Footer/>
    </div>
    
    </React.Fragment>


}



export default SolutionsPedestrian